import React from 'react'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import styled from 'styled-components'
import device, { maxDevice } from './styles/device'

const Nav = styled.nav`
  flex: 0 1 auto;
  margin-left: auto;
  transition: 0.5s;

  @media ${maxDevice.tablet} {
    position: fixed;
    z-index: 2;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 60px);
    background-color: white;
    opacity: 0;
  }

  &.closed {
    @media ${maxDevice.tablet} {
      transform: translateY(calc(-100% - 60px));
    }
  }

  &.open {
    @media ${maxDevice.tablet} {
      transform: 0;
      opacity: 1;
    }
  }
`

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
  }

  li a {
    text-decoration: none;
    color: ${props => props.theme.colorDark};
  }

  li a:hover {
    color: ${props => props.theme.colorPrimary};
  }

  li.langselector {
    background-color: #f1f1f1;
    border-radius: 4px;
    padding: 2px;
    line-height: 1;

    a {
      padding: 4px 8px 2px 8px;
      font-size: 1.6rem;
      border-radius: 3px;
      transition: 0.25s;

      &:hover {
        background-color: ${props => props.theme.colorPrimary};
        color: white;
      }

      &.active {
        background-color: white;

        &:hover {
          background-color: white;
          cursor: default;
          color: ${props => props.theme.colorDark};
        }
      }
    }
  }

  @media ${maxDevice.tablet} {
    li:first-child {
      border-top: 1px solid #eee;
    }

    li {
      border-bottom: 1px solid #eee;
    }

    li a {
      display: block;
      padding: 10px 20px;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin: -10px;

    li {
      position: relative;
      margin: 10px;
      flex: 0 1 auto;
    }

    li a {
      cursor: pointer;
    }

    li ul {
      transition: all 0.5s cubic-bezier(0.14, 0.53, 0.96, 0.48);
      opacity: 0;
      visibility: hidden;
      transform: translateY(50px);
    }

    li.expandable:hover ul {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`

const SubNavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    font-size: 1.8rem;
  }

  @media ${maxDevice.tablet} {
    border-left: 20px solid #eee;

    li:last-child {
      border-bottom: 0;
    }
  }

  @media ${device.tablet} {
    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    background-color: white;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    li {
      margin: 0;
      border-bottom: 1px solid #eee;
    }

    li:last-child {
      border-bottom: 0;
    }

    li a {
      display: block;
      padding: 10px;
    }
  }
`

const MyLink = ({ location, url, text, lang }) => {
  switch (location) {
    case '/':
      return (
        <ScrollLink to={url} smooth={true} offset={-70}>
          {text}
        </ScrollLink>
      )
      break
    case '/en/':
      return (
        <ScrollLink to={url} smooth={true} offset={-70}>
          {text}
        </ScrollLink>
      )
    default:
      const linkUrl = lang === 'en' ? `/en/#${url}` : `/#${url}`
      return <Link to={linkUrl}>{text}</Link>
      break
  }
}

const Navigation = props => {
  const aboutLink = {
    text: props.lang === 'en' ? 'About' : 'Über uns',
    url: 'about',
  }

  const servicesLink = {
    text: props.lang === 'en' ? 'Services' : 'Leistungen',
    url: 'services',
  }

  const copackingLink = {
    text: props.lang === 'en' ? 'Co-packing' : 'Co-Packing',
    url: 'copacking',
  }

  const logisticsLink = {
    text: props.lang === 'en' ? 'Storage and logistics' : 'Lagerung & Logistik',
    url: 'logistic',
  }

  const posLink = {
    text: props.lang === 'en' ? 'POS development' : 'POS-Entwicklung',
    url: 'pos',
  }

  const careerLink = {
    text: props.lang === 'en' ? 'Career' : 'Karriere',
    url: 'career',
  }

  const contactLink = {
    text: props.lang === 'en' ? 'Contact' : 'Kontakt',
    url: 'contact',
  }

  return (
    <Nav className={props.navOpen ? 'open' : 'closed'}>
      <NavList onClick={props.toggleNav}>
        <li>
          <MyLink
            url={aboutLink.url}
            text={aboutLink.text}
            location={props.location}
            lang={props.lang}
          />
        </li>
        <li className="expandable">
          <MyLink
            url={servicesLink.url}
            text={servicesLink.text}
            location={props.location}
            lang={props.lang}
          />
          <SubNavList>
            <li>
              <MyLink
                url={copackingLink.url}
                text={copackingLink.text}
                location={props.location}
                lang={props.lang}
              />
            </li>
            <li>
              <MyLink
                url={logisticsLink.url}
                text={logisticsLink.text}
                location={props.location}
                lang={props.lang}
              />
            </li>
            <li>
              <MyLink
                url={posLink.url}
                text={posLink.text}
                location={props.location}
                lang={props.lang}
              />
            </li>
          </SubNavList>
        </li>
        <li>
          <MyLink
            url={careerLink.url}
            text={careerLink.text}
            location={props.location}
            lang={props.lang}
          />
        </li>
        <li>
          <MyLink
            url={contactLink.url}
            text={contactLink.text}
            location={props.location}
            lang={props.lang}
          />
        </li>
        <li className="langselector">
          <Link to="/" className={props.lang === 'en' ? '' : 'active'}>
            DE
          </Link>
          <Link to="/en/" className={props.lang === 'en' ? 'active' : ''}>
            EN
          </Link>
        </li>
      </NavList>
    </Nav>
  )
}

export default Navigation

import React from 'react'
import device from './styles/device'
import styled from 'styled-components'

const HamburgerContainer = styled.div`
  width: 23px;
  height: 23px;
  position: absolute;
  top: 0;
  right: 25px;
  cursor: pointer;
  transition: 0.5s ease-in-out;

  @media ${device.tablet} {
    display: none;
  }

  &.open span {
    &:nth-child(1),
    &:nth-child(4) {
      top: 10px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
`

const SpanLine = styled.span`
  width: 100%;
  display: block;
  position: absolute;
  height: 3px;
  background-color: #ccc;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  opacity: 1;

  &:nth-child(1) {
    top: 4px;
  }
  &:nth-child(2),
  &:nth-child(3) {
    top: 10px;
  }
  &:nth-child(4) {
    top: 16px;
  }
`

const Hamburger = ({ clickHandler, navOpen }) => (
  <HamburgerContainer
    onClick={clickHandler}
    className={navOpen ? 'open' : 'closed'}
  >
    <SpanLine />
    <SpanLine />
    <SpanLine />
    <SpanLine />
  </HamburgerContainer>
)

export default Hamburger

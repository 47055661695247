import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'
import device from './styles/device'
import Navigation from './navigation'
import Hamburger from './hamburger'

import { Location } from '@reach/router'

const Topbar = styled.header`
  background-color: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;

  .container {
    display: flex;
    flex-direction: row;
  }

  @media ${device.tablet} {
    height: 100px;

    .container {
      height: 30px;
    }

    #logo {
      max-width: 300px;
    }
  }
`

const Logo = styled.div`
  flex: 0 0 250px;
  max-width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.tablet} {
    flex: 0 0 300px;
    max-width: 300px;
  }
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: false,
    }
    this.toggleNav = this.toggleNav.bind(this)
  }

  toggleNav() {
    this.setState(prevState => ({
      navOpen: !prevState.navOpen,
    }))
  }

  render() {
    return (
      <Topbar>
        <div className="container">
          <Logo>
            <Link to="/" title="Nach oben">
              <svg
                width="335"
                height="30"
                viewBox="0 0 335 30"
                xmlns="http://www.w3.org/2000/svg"
                id="logo"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    d="M4.2 15.335c0 1.462.373 2.831 1.12 4.106.746 1.276 1.772 2.388 3.078 3.337 1.308.948 2.832 1.695 4.574 2.24 1.742.544 3.608.815 5.599.815 2.644 0 5.047-.434 7.21-1.305 2.161-.871 3.88-2.085 5.156-3.64v4.899c-1.68 1.12-3.563 1.991-5.647 2.613-2.084.623-4.323.933-6.72.933-2.582 0-5-.366-7.255-1.095-2.256-.732-4.223-1.728-5.902-2.988-1.68-1.259-3.003-2.745-3.966-4.456A10.936 10.936 0 0 1 0 15.335c0-1.929.482-3.748 1.447-5.46.963-1.71 2.286-3.203 3.966-4.48 1.68-1.274 3.646-2.278 5.902-3.008 2.255-.731 4.673-1.097 7.256-1.097 2.396 0 4.635.319 6.72.956 2.083.638 3.966 1.517 5.646 2.637v4.946c-1.275-1.555-2.995-2.783-5.157-3.687-2.162-.9-4.565-1.352-7.21-1.352-1.99 0-3.856.272-5.598.816-1.742.545-3.266 1.299-4.574 2.262-1.306.966-2.332 2.086-3.078 3.36a7.996 7.996 0 0 0-1.12 4.107M42.461 13.189h11.526c2.333 0 3.974-.31 4.923-.935.948-.62 1.423-1.539 1.423-2.752 0-1.213-.475-2.13-1.423-2.753-.949-.621-2.59-.933-4.923-.933H42.461v7.373zm0 15.164h-3.966V2.316h15.958c1.183 0 2.372.117 3.57.35 1.197.234 2.278.63 3.243 1.19.964.56 1.75 1.3 2.356 2.217.607.918.91 2.061.91 3.43 0 1.025-.178 1.929-.536 2.705a6.152 6.152 0 0 1-1.423 1.984 7.707 7.707 0 0 1-2.076 1.353c-.794.358-1.626.63-2.497.816l7.746 11.992h-4.667l-7.373-11.665H42.461v11.665zM73.304 2.316H99.2v3.5H77.316v7.14h18.432v3.499H77.27v8.399H99.2v3.5H73.305z"
                    fill="#363636"
                  />
                  <path
                    d="M128.47 2.315c5.474 1.799 9.43 6.942 9.43 13.018 0 6.074-3.956 11.217-9.43 13.016H141.6V2.315H128.47zM110.485 15.332c0-6.075 3.956-11.219 9.429-13.017h-13.128V28.35h13.128c-5.473-1.798-9.43-6.942-9.43-13.017"
                    fill="#6F90B8"
                  />
                  <path
                    d="M153.32 13.073h11.11c2.16 0 3.694-.302 4.602-.906.909-.603 1.363-1.458 1.363-2.562 0-1.105-.454-1.949-1.363-2.537-.908-.585-2.443-.878-4.603-.878h-11.108v6.883zm0 15.27h-4.267V2.357h15.788c1.165 0 2.348.122 3.548.363 1.2.241 2.28.64 3.24 1.19a6.748 6.748 0 0 1 2.34 2.225c.6.932.901 2.09.901 3.47 0 1.38-.3 2.535-.9 3.467a6.985 6.985 0 0 1-2.34 2.252c-.96.57-2.042.975-3.24 1.216a17.94 17.94 0 0 1-3.55.363h-11.52v11.438zM205.804 16.282h-21.805v12.06h-4.27V2.359H184v10.094h21.805V2.358h4.269v25.985h-4.269zM227.43 16.645h10.028l-4.989-7.868-5.04 7.868zm12.446 3.778h-14.864l-5.04 7.92h-5.04L232.47.856l17.589 27.485h-5.092l-5.09-7.919zM259.186 13.073h11.109c2.159 0 3.694-.302 4.603-.906.907-.603 1.363-1.458 1.363-2.562 0-1.105-.456-1.949-1.363-2.537-.909-.585-2.444-.878-4.603-.878h-11.109v6.883zm0 15.27h-4.268V2.357h15.84c1.165 0 2.339.122 3.522.363a10.71 10.71 0 0 1 3.214 1.19 6.743 6.743 0 0 1 2.34 2.225c.6.932.9 2.09.9 3.47 0 1.035-.171 1.932-.513 2.692a6.757 6.757 0 0 1-1.363 1.992 7.108 7.108 0 0 1-1.98 1.398c-.755.361-1.561.647-2.418.853l7.663 11.801h-4.989l-7.251-11.438h-10.697v11.438zM323.19 10.33l-12.6 18.995-12.6-18.995-6.584 18.013h-4.423l9.926-27.33 13.68 20.6 13.68-20.6 9.926 27.33h-4.422z"
                    fill="#363636"
                  />
                </g>
              </svg>
            </Link>
          </Logo>
          <Location>
            {({ location }) => {
              return (
                <Navigation
                  lang={this.props.lang}
                  navOpen={this.state.navOpen}
                  toggleNav={this.toggleNav}
                  location={location.pathname}
                />
              )
            }}
          </Location>
          <Hamburger
            clickHandler={this.toggleNav}
            navOpen={this.state.navOpen}
          />
        </div>
      </Topbar>
    )
  }
}

export default Header

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import theme from './styles/theme'
import Footer from '../components/footer'
import './styles/layout.css'

const GlobalStyle = createGlobalStyle`
`

const Layout = props => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <GlobalStyle />
            <Header
              siteTitle={data.site.siteMetadata.title}
              lang={props.lang}
            />
            {props.children}
            <Footer lang={props.lang} />
          </React.Fragment>
        </ThemeProvider>
      )}
    />
  )
}

export default Layout
